
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface Teacher {
  _id: string;
  fullname: string;
  levels: string[];
  subjects: string[];
  color: string;
  photo: string;
  birthDate: string;
}

export default defineComponent({
  name: "Teacher-list",
  components: {},
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const filteredTeachers = ref<Teacher[]>([]);
    const teacherList = ref<Teacher[]>([]);

    // eslint-disable-next-line
    const levels = ref<any[]>(store.getters.currentUser.levels);

    const loading = ref<boolean>(true);

    onMounted(async () => {
      ApiService.get("/lms/advisor/teachers")
        .then(({ data }) => {
          teacherList.value = data.filter(
            (tch) => tch._id != store.getters.currentUser._id
          );
          filteredTeachers.value = [...teacherList.value];
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loading.value = false;
        });

      setCurrentPageBreadcrumbs(t("teacher.myTeachers"), []);
    });

    const search = ref<string>("");
    const selectedLevel = ref<string>("");

    const searchItems = () => {
      filteredTeachers.value = teacherList.value.filter((teacher) => {
        const toSearch = search.value.toLowerCase();
        return (
          (teacher.fullname.toLowerCase().includes(toSearch) ||
            teacher.fullname.toLowerCase().includes(toSearch)) &&
          (selectedLevel.value == "" ||
            teacher.levels.includes(selectedLevel.value))
        );
      });
    };

    return {
      t,
      moment,
      teacherList,
      search,
      searchItems,
      apiUrl: store.getters.serverConfigUrl.base_url + "/",
      selectedLevel,
      filteredTeachers,
      loading,
      levels,
    };
  },
});
